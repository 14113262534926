import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../containers/Layout"
import { useTranslation } from "react-i18next"
import TextField from "@mui/material/TextField/TextField"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import useFormErrors from "../hooks/useFormErrors"
import Button from "../components/genericComponents/Button"
import SEO from "../components/genericComponents/SEO"
import { StaticImage } from "gatsby-plugin-image"
const ContactPage = props => {
  const { t } = useTranslation()
  const validations = ["type", "name", "phone", "email", "message"]
  const [disabled, setDisabled] = useState(false)
  const [message, setMessage] = useState()
  const { form, handleChange, onBlur, errors, setError, setInForm } =
    useFormErrors({ type: null, name: "", phone:"", email: "", message: "" }, validations)

  function submit() {
    //Validate required and formats fields
    let validateErrors = {}
    validations.forEach(name => {
      let value
      value = form[name]
      if (!value) {
        validateErrors[name] = t("error_form_obligatory_field")
      } else if (
        name === "email" &&
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        validateErrors[name] = t("error_form_email_format")
      } else if (name === 'phone' && !/^(\+34|0034|34)?[ -]*(6|7)[ -]*([0-9][ -]*){8}/.test(value.trim())) {
				validateErrors[name] = t('error_form_phone_format');
			}
    })

    setError({ ...validateErrors })
    //No errors; dispatch action
    if (Object.keys(validateErrors).length === 0) {
      setDisabled(true)
      setMessage(undefined)
      fetch(
      `${process.env.URL_ENV_SEND}/api/users/sendMailContactWeb`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Beared`,
          },
          body: JSON.stringify({
            ...form,
            apiKey:process.env.API_KEY
          }),
        }
      )
        .then(response => response.json())
        .then(data => {
          if(data.success){
            setMessage(t('contact_message_ok'))
          }else{
            setMessage(t('contact_message_ko'))
          }
          setDisabled(false);
        })
    }
  }

  return (
    <Layout
      navBarProps={{
        navClass: "is--white",
      }}
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
      <SEO
        title={t("seo_contact_title")}
        desc={t("seo_contact_description")}
        pathname={"/contacto/"}
        article={false}
        section={t("seo_contact_section")}
        tags={{
          tag_1: t("seo_contact_tag1"),
          tag_2: t("seo_contact_tag2"),
          tag_3: t("seo_contact_tag3"),
          tag_4: t("seo_contact_tag4"),
        }}
      />
      <section className="contact-header container-generic" data-scroll-section>
        <div className="contact-header__content">
          <div className="contact-header__content-image">
            <StaticImage
              src="../assets/images/contact-header.jpg"
              alt={t("contact_title")}
              placeholder="blurred"
            />
          </div>
          <div className="contact-header__content-text">
            <h1 className="contact-header__content-text-title">
              {t("contact_title")}
            </h1>
            {createAutocomplete(
              t("contact_field_type_label"),
              t("contact_field_type_placeholder"),
              "type",
              true,
              [
                t("contact_field_type_option1"),
                t("contact_field_type_option2"),
                t("contact_field_type_option3"),
                t("contact_field_type_option4"),
              ]
            )}
            {createTextField(
              t("contact_field_name_label"),
              t("contact_field_name_placeholder"),
              "name",
              true
            )}
            {createTextField(
              t("contact_field_phone_label"),
              t("contact_field_phone_placeholder"),
              "phone",
              true
            )}
            {createTextField(
              t("contact_field_email_label"),
              t("contact_field_email_placeholder"),
              "email",
              true
            )}
            {createTextField(
              t("contact_field_message_label"),
              t("contact_field_message_placeholder"),
              "message",
              true,
              {
                multiline: true,
                rows: 4,
              }
            )}
            <Button
              label={t("contact_btn")}
              buttonClass="contained"
              onClick={() => submit()}
              disabled={disabled}
            />
            {message && <div className="contact-header__content-text-message">{message}</div>}
          </div>
        </div>
      </section>
      <section
        className="contact-locations container-generic"
        data-scroll-section
      >
        <h2>{t("contact_localization_title")}</h2>
        <div className="contact-locations__content">
          <div className="contact-locations__content-item">
            <div className="contact-locations__content-item-content">
              <div className="contact-locations__content-item-content-icon-bcn" />
              <div className="contact-locations__content-item-content-location">
                {t("contact_localization_bcn_title")}
              </div>
              <p>{t("contact_localization_bcn_address")}</p>
              <p>{t("contact_localization_bcn_email")}</p>
              <p>{t("contact_localization_bcn_phone")}</p>
              <Button
                label={t("contact_localization_btn")}
                buttonClass="text"
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/nScNch6qZFezc4GZ7",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )

  function createAutocomplete(
    label,
    placeholder,
    name,
    required,
    options,
    propsComponent = {}
  ) {
    return (
      <Autocomplete
        id={name}
        name={name}
        options={options}
        value={form[name]}
        required={required}
        onChange={(event, value) => {
          setInForm(name, value)
        }}
        {...propsComponent}
        renderInput={params => (
          <TextField
            {...params}
            variant={"filled"}
            label={label}
            placeholder={placeholder}
            fullWidth
            InputLabelProps={{ shrink: true }}
            required={required}
            error={Boolean(errors[name])}
            helperText={errors[name]}
          />
        )}
      />
    )
  }

  function createTextField(
    label,
    placeholder,
    name,
    required,
    propsTextField = {}
  ) {
    return (
      <TextField
        error={Boolean(errors[name])}
        helperText={errors[name]}
        required={required}
        id={name}
        name={name}
        value={form[name]}
        label={label}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        variant="filled"
        fullWidth
        InputLabelProps={{ shrink: true }}
        {...propsTextField}
      />
    )
  }
}

export default ContactPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
